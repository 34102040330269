<template functional>
    <footer class="flex flex-wrap justify-center text-sm text-gray-500 pt-6">
        <a href="https://www.h2g.ch/impressum" class="hover:text-gray-300 focus:text-gray-300 focus:outline-none mx-4 mb-2">Impressum</a>
    </footer>
</template>

<script>
export default {
};
</script>
